/**
 * Theme SCSS
 *
 * Use this file to overwrite variables decalred in the bootstrap variables file
 *
 * this file is intended to form the base styles of the engine room application
 *
 * PLEASE NOTE:
 * DO NOT add styles into this file for a specific component
 * you should consider all components as self contained applications
 * 
*/

// import the custom font we need
@import url("https://fonts.googleapis.com/css?family=Lato");

/**
 * Theme Overrides
 *
 * Theme overrides have been designed graphically using https://bootstrap.build/app/v4.0/
 *
 * to preview changes to the underlying bootsrap theme please consider 
 * uploading the theme overrides below in a _variables.scss file
*/
$gray-base: #5e5d63;
$red: #cf0000;
$orange: #ea7525;
$yellow: #eec245;
$green: #24a21a;
$cyan: #3ca9d0;
$darkgray: #3c3c3c;
$lightwhite: #f4f4f4;
$primary: $orange;
$body-color: $gray-base;
$secondary: lighten($darkgray, 50);
$danger: $red;
$enable-rounded: true;
$enable-shadows: true;
$border-radius: 0.5rem;
$font-family-sans-serif: Lato;
$font-size-base: 0.75rem;
$font-size-lg: ($font-size-base * 1.35);
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.15;
$font-weight-normal: 400;
$headings-font-weight: $font-weight-normal;
$alert-bg-level: -10;
$alert-border-level: 5;
$alert-color-level: 5;
$navbar-padding-y: 0px;
$dropdown-spacer: 0px;
$dropdown-min-width: 100%;
$dropdown-link-active-bg: none;
$dropdown-link-active-color: $orange;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    )
  ),
  $spacers
);
